import React from 'react'
import _ from 'lodash'
import SimpleMDE from 'react-simplemde-editor'

const emptyStructure = () => ({
  akcje: 0,
  dluzne: 0,
  gotowka: 0,
  alternatywne: 0,
  pln: 0,
  eur: 0,
  gbp: 0,
  usd: 0,
  subclasses: [0, 0, 0, 0, 0, 0, 0, 0]
})

const subclasses = [
  'Polski rynek pieniężny',
  'Polskie obligacje skarbowe',
  'Polskie obligacje korporacyjne',
  'Obligacje z rynków rozwiniętych',
  'Obligacje z rynków rozwijających się',
  'Obligacje korporacyjne o ratingu inwestycyjnych',
  'Polskie akcje dużych spółek',
  'Gotówka'
]

const newElement = () => ({
  subclass: 0,
  name: '',
  value: '',
  recommendation: 'buy',
  currency: 'PLN',
  price: 0,
  amount: 0
})

export default class Creator extends React.Component {
  state = {
    current: emptyStructure(),
    wanted: emptyStructure(),
    currentConcrete: [],
    recommendations: []
  }
  onChange = path => e => {
    const val = e.target ? e.target.value : e
    this.setState(state => {
      _.set(state, path, val)
      return state
    }, this.save)
  }
  newElement = type => () => {
    this.setState(state => {
      if (type === 'current')
        state.currentConcrete.push(newElement())
      else
        state.recommendations.push(newElement())
      return state
    }, this.save)
  }
  deleteElement = (type, ind) => {
    this.setState(state => {
      if (type === 'current')
        state.currentConcrete.splice(ind, 1)
      else
        state.recommendations.splice(ind, 1)
      return state
    }, this.save)
  }
  save = () => {
    this.props.onChange(JSON.stringify(this.state))
  }
  load = value => {
    if (JSON.stringify(this.state) === value) return
    this.setState(JSON.parse(value))
  }
  componentDidMount () {
    this.load(this.props.value)
  }
  componetnWillReceiveProps (props) {
    this.load(props.value)
  }
  render () {
    return <div>
      <hr />
      <div className='columns'>
        <div className='column is-3'>
          <h2 className='title is-6'>Aktualne klasy</h2>
          Akcje
          <input className='input' value={this.state.current.akcje} onChange={this.onChange('current.akcje')} />
          Dłużne
          <input className='input' value={this.state.current.dluzne} onChange={this.onChange('current.dluzne')} />
          Gotówka
          <input className='input' value={this.state.current.gotowka} onChange={this.onChange('current.gotowka')} />
          Alternatywne
          <input className='input' value={this.state.current.alternatywne} onChange={this.onChange('current.alternatywne')} />
        </div>
        <div className='column is-3'>
          <h2 className='title is-6'>Aktualne waluty</h2>
          PLN
          <input className='input' value={this.state.current.pln} onChange={this.onChange('current.pln')} />
          EUR
          <input className='input' value={this.state.current.eur} onChange={this.onChange('current.eur')} />
          GBP
          <input className='input' value={this.state.current.gbp} onChange={this.onChange('current.gbp')} />
          USD
          <input className='input' value={this.state.current.usd} onChange={this.onChange('current.usd')} />
        </div>

        <div className='column is-3'>
          <h2 className='title is-6'>Pożądane klasy</h2>
          Akcje
          <input className='input' value={this.state.wanted.akcje} onChange={this.onChange('wanted.akcje')} />
          Dłużne
          <input className='input' value={this.state.wanted.dluzne} onChange={this.onChange('wanted.dluzne')} />
          Gotówka
          <input className='input' value={this.state.wanted.gotowka} onChange={this.onChange('wanted.gotowka')} />
          Alternatywne
          <input className='input' value={this.state.wanted.alternatywne} onChange={this.onChange('wanted.alternatywne')} />
        </div>

        <div className='column is-3'>
          <h2 className='title is-6'>Pożądane waluty</h2>
          PLN
          <input className='input' value={this.state.wanted.pln} onChange={this.onChange('wanted.pln')} />
          EUR
          <input className='input' value={this.state.wanted.eur} onChange={this.onChange('wanted.eur')} />
          GBP
          <input className='input' value={this.state.wanted.gbp} onChange={this.onChange('wanted.gbp')} />
          USD
          <input className='input' value={this.state.wanted.usd} onChange={this.onChange('wanted.usd')} />
        </div>
      </div>

      <hr />
      <div className='columns'>
        <div className='column is-3'>
          <h2 className='title is-6'>Pożądane subklasy</h2>
          Polski rynek pieniężny
          <input className='input' value={this.state.wanted.subclasses[0]} onChange={this.onChange('wanted.subclasses.0')} />
          Polskie obligacje skarbowe
          <input className='input' value={this.state.wanted.subclasses[1]} onChange={this.onChange('wanted.subclasses.1')} />
          Polskie obligacje korporacyjne
          <input className='input' value={this.state.wanted.subclasses[2]} onChange={this.onChange('wanted.subclasses.2')} />
          Obligacje z rynków rozwiniętych
          <input className='input' value={this.state.wanted.subclasses[3]} onChange={this.onChange('wanted.subclasses.3')} />
          Obligacje z rynków rozwijających się
          <input className='input' value={this.state.wanted.subclasses[4]} onChange={this.onChange('wanted.subclasses.4')} />
          Obligacje korporacyjne o ratingu inwestycyjnych
          <input className='input' value={this.state.wanted.subclasses[5]} onChange={this.onChange('wanted.subclasses.5')} />
          Polskie akcje dużych spółek
          <input className='input' value={this.state.wanted.subclasses[6]} onChange={this.onChange('wanted.subclasses.6')} />
          Gotówka
          <input className='input' value={this.state.wanted.subclasses[7]} onChange={this.onChange('wanted.subclasses.7')} />
          Podsumowanie
          <SimpleMDE
            onChange={this.onChange('summary')}
            value={this.state.summary}
          />
        </div>
        <div className='column is-3'>
          <h2 className='title is-6'>Zmienne</h2>
          Szacowane maksymalne roczne obsunięcie kapitału - aktualne
          <input className='input' value={this.state.current.var1} onChange={this.onChange('current.var1')} />
          Szacowana maksymalna strata - aktualna
          <input className='input' value={this.state.current.var2} onChange={this.onChange('current.var2')} />
          Szacowane roczna stopa zwrotu - aktualna
          <input className='input' value={this.state.current.var3} onChange={this.onChange('current.var3')} />

          Szacowane maksymalne roczne obsunięcie kapitału - pożądane
          <input className='input' value={this.state.wanted.var1} onChange={this.onChange('wanted.var1')} />
          Szacowana maksymalna strata - pożądana
          <input className='input' value={this.state.wanted.var2} onChange={this.onChange('wanted.var2')} />
          Szacowane roczna stopa zwrotu - pożądana
          <input className='input' value={this.state.wanted.var3} onChange={this.onChange('wanted.var3')} />
        </div>
        <div className='column is-6'>
          Komentarz aktualny
          <SimpleMDE
            onChange={this.onChange('current_comment')}
            value={this.state.current_comment}
          />
          Komentarz wzorcowy
          <SimpleMDE
            onChange={this.onChange('model_comment')}
            value={this.state.model_comment}
          />
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <h2 className='title is-6'>Aktualna zawartość portfela</h2>
          {this.state.currentConcrete.map((el, ind) => <div>
            <div className='select'>
              <select value={el.subclass} onChange={this.onChange('currentConcrete.' + ind + '.subclass')}>
                {subclasses.map((subclass, idx) => <option value={idx}>{subclass}</option>)}
              </select>
            </div><br /><br />
            Nazwa
            <input className='input' value={el.name} onChange={this.onChange('currentConcrete.' + ind + '.name')} /><br />
            Wartość
            <input className='input' value={el.value} onChange={this.onChange('currentConcrete.' + ind + '.value')} /><br /><br />
            <div onClick={() => this.deleteElement('current', ind)} className='button is-danger'>Usuń</div>
            <hr />
          </div>)}
          <div className='button is-success' onClick={this.newElement('current')}>Nowa zawartość</div>
        </div>
        <div className='column'>
          <h2 className='title is-6'>Rekomendacje</h2>
          {this.state.recommendations.map((el, ind) => <div>
            <div className='select'>
              <select value={el.subclass} onChange={this.onChange('recommendations.' + ind + '.subclass')}>
                {subclasses.map((subclass, idx) => <option value={idx}>{subclass}</option>)}
              </select>
            </div><br /><br />
            Nazwa
            <input className='input' value={el.name} onChange={this.onChange('recommendations.' + ind + '.name')} /><br /><br />
            Rekomendacja<br />
            <div className='select'>
              <select value={el.recommendation} onChange={this.onChange('recommendations.' + ind + '.recommendation')}>
                <option value='buy'>Kupuj</option>
                <option value='sell'>Sprzedaj</option>
              </select>
            </div><br /><br />
            Waluta<br />
            <div className='select'>
              <select value={el.currency} onChange={this.onChange('recommendations.' + ind + '.currency')}>
                <option value='pln'>PLN</option>
                <option value='eur'>EUR</option>
                <option value='usd'>USD</option>
                <option value='gbp'>GBP</option>
              </select>
            </div><br /><br />
            Cena
            <input className='input' value={el.price} onChange={this.onChange('recommendations.' + ind + '.price')} /><br /><br />
            Ilość
            <input className='input' value={el.amount} onChange={this.onChange('recommendations.' + ind + '.amount')} /><br /><br />
            <div onClick={() => this.deleteElement('recommendation', ind)} className='button is-danger'>Usuń</div>
            <hr />
          </div>)}
          <div className='button is-success' onClick={this.newElement('recommendation')}>Nowa rekomendacja</div>
        </div>
      </div>
    </div>
  }
}
