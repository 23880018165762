import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Route, withRouter } from 'react-router-dom'
import {StorworkInit} from 'storwork-stores'
import {observer} from 'mobx-react'
import Dropzone from 'react-dropzone'
import Input from './components/Input'
import getStore from './Store'
import Creator from './components/Creator'
import { fileUpload } from './services/files'

const emptyInformation = type => ({
  content: '{}',
  type
})

const Information = props => {
  const content = JSON.parse(props.content)[0]
  return <div>{content.filename}</div>
}

const Recommendation = props => {
  const content = JSON.parse(props.content)
  return <div>{props.id}</div>
}

const InformationContainer = observer(props => {
  const {id, name, type, client} = props
  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      fileUpload(file, type, id, 'App\\Modules\\B2CIntegration\\Client')
    });
  }
  return <div>
    <h1 className='title is-6'>{name}</h1>
    <nav className="panel">
      <a className="panel-block">
        <span className="panel-icon">
          <i className="fas fa-book" aria-hidden="true"></i>
        </span>
        {client.information.filter(el => el.type === type).map(el => <Information {...el} />)}
      </a>
    </nav>
    <Dropzone onDrop={onDrop} className='dropzone'>Upuść plik lub kliknij...</Dropzone>
  </div>
})


const ClientData = observer( class _ClientData extends React.Component {
  StoreInput = Input(this.props.store)
  setupInfo = () => {
    const {id, client, store, index} = this.props
    const clientInfoIndex = client.information.findIndex(el => el.type === 'info')
    const infoIndex = clientInfoIndex > -1 ? clientInfoIndex : client.information.length
    if (clientInfoIndex === -1) {
      client.information.push(emptyInformation('info'))
    }
    if (client.information[infoIndex].content === '[]')
      client.information[infoIndex].content = '{}'
  }
  componentWillReact () {
    this.setupInfo()
  }
  componentDidMount () {
    this.setupInfo()
  }
  render () {
    const {id, client, store, index} = this.props
    const infoIndex = client.information.findIndex(el => el.type === 'info')
    console.log(JSON.stringify(client.information));
    console.log('records.' + index + '.information.' + infoIndex + '.content');
    if (infoIndex === -1) return null
    return <div>
      <h1 className='title is-6'>Dane klienta</h1>
      <div className='select'>
        <this.StoreInput path={'records.' + index + '.information.' + infoIndex + '.content'} json='type' options={[{
            value: '',
            label: '(wybierz)'
          },{
            value: 'op',
            label: 'Osoba prawna'
          }, {
              value: 'of',
              label: 'Osoba fizyczna'
            }]} />
        </div><br /><br />
      <label className='label'>Nazwa:</label>
      <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='name'/>
      <label className='label'>Zamieszkanie:</label>
      <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='address'/>
      <label className='label'>Korespondencja:</label>
      <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='postal'/>
      <label className='label'>US:</label>
      <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='us'/>
      <label className='label'>NIP:</label>
      <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='nip'/>
      {JSON.parse(client.information[infoIndex].content).type === 'op' ? <React.Fragment>
        <label className='label'>Regon:</label>
        <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='regon'/>
      </React.Fragment> : <React.Fragment>
        <label className='label'>PESEL:</label>
        <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='pesel'/>
      </React.Fragment>}
      <label className='label'>Telefon:</label>
      <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='phone'/>
      <label className='label'>Email:</label>
      <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='email'/>
      {JSON.parse(client.information[infoIndex].content).type === 'op' && <React.Fragment>
        <label className='label'>Reprezentacja:</label>
        <this.StoreInput className='input' path={'records.' + index + '.information.' + infoIndex + '.content'} json='reprezentacja'/>
      </React.Fragment>}<br /><br />
    <label className='label'>Powiadomienia:</label>
    <div className='select'>
      <this.StoreInput path={'records.' + index + '.information.' + infoIndex + '.content'} json='notifications' options={[
          {value: '', label: 'brak'},
          {value: 'email', label: 'email'},
          {value: 'sms', label: 'sms'}
        ]}/>
    </div>
    <a className='button is-success' onClick={() => this.props.store.sync(id)}>Zapisz</a>
    </div>
  }
})

const RecommendationEditor = observer(props => {
  return <div>
    <Creator value={props.client.information[props.index].content} onChange={value => {
        props.client.information[props.index].content = value
      }} />
    <button onClick={() => {
        props.client.information[props.index].type = 'recommendation'
        getStore().sync(props.client.id)
      }}>Save</button>
  </div>
})

const RecommendationContainer = observer(class _RecommendationContainer extends React.Component {
  render () {
    const {id, client} = this.props
    const newIndex = client.information.findIndex(el => el.type === 'new_recommendation')
    const recommendations = client.information.filter(el => el.type === 'recommendation')
    const lastRecommendation = recommendations.sort((el1, el2) => el1.id - el2.id)[0]
    console.log(JSON.stringify(lastRecommendation));
    return <div style={{padding: 25}}>
      <h1 className='title is-6'>Rekomendacje</h1>
      {newIndex > -1 ? <RecommendationEditor client={client} index={newIndex} /> : <div>
        {lastRecommendation && <div>
          Ostatnia rekomendacja: <span onClick={() => {
            const index = client.information.findIndex(el => el.id === lastRecommendation.id)
            client.information[index].type = 'new_recommendation'
          }}>{lastRecommendation.id}</span>
        </div>}
        <button onClick={() => {
          client.information.push(emptyInformation('new_recommendation'))
        }} className='button is-success'>Nowa rekomendacja</button>
      </div>}
    </div>
  }
})

const Client = observer(props => {
  const id = parseInt(props.match.params.id)
  const client = getStore().records.find(el => el.id === id)
  const clientIndex = getStore().records.findIndex(el => el.id === id)
  if (!client) return null
  return <div>
    <RecommendationContainer client={client} id={id} />
    <hr />
    <div className='columns'>
      <div className='column is-4' style={{padding: 25}}>
        <ClientData client={client} index={clientIndex} store={getStore()} id={id} />
      </div>
      <div className='column is-4' style={{padding: 25}}>
        <InformationContainer name='Faktury' type='invoice' client={client} id={id} />
      </div>
      <div className='column is-4' style={{padding: 25}}>
        <InformationContainer name='Umowy' type='agreement' client={client} id={id} />
      </div>
    </div>
  </div>
})

const buildShortInfo = info => {
  const content = JSON.parse(info.content)
  return content.name + '(' + (content.nip ? content.nip : content.pesel) + ')'
}

const Application = withRouter(observer(props => <div className='columns'>
  <div className='column is-2'>
    <aside class="menu">
      <ul class="menu-list">
        {props.store.records.map(el => {
          const info = el.information.find(el => el.type === 'info')
          const infoString = info ? buildShortInfo(info) : '(brak danych)'
          return <li><Link className={props.location.pathname.split('/')[1] == el.id ? "is-active" : ''} to={'/' + el.id}>
            {!info ? <span>ID: {el.id}</span> : <span>{infoString}</span>}
          </Link></li>
        })}
      </ul>
    </aside>
  </div>
  <div className='column is-10'>
    <Route path='/:id' component={Client}/>
  </div>
</div>
  ))

const ApplicationWithStore = props => <Application store={getStore()} />

class App extends Component {
  render() {
    return <StorworkInit loggedIn={<ApplicationWithStore />} />
  }
}

export default App;
