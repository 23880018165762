import { ListStore } from 'storwork-stores'

let store = null
let interval = null

export default () => {
  if (!store) {
    store = new ListStore({type: 'client', staticFilters: {
      order: 'id asc'
    }, entities: [{
      path: '',
      alwaysSave: true,
      fields: [],
      type: 'Client',
      multi: false
    }, {
      path: 'information',
      fields: ['content', 'type'],
      type: 'Information',
      multi: true
    }]})
    // interval = setInterval(store.loadRecords, 2000)
  }
  return store
}
